<template>
     <div style="margin-top: 60px;">
    <div class="multi-lang"> 
      <u style="margin-top: 60px;" class="linguval">{{$t("TERMS OF USE")}} </u>
    </div  >
    <div v-html="dataset"> </div>
 
  </div>
  
  
</template>

<script>
import Utility from "@/mixins/Utility.js";
export default {
 data() {
  return {
    dataset :''
  }
 },
 mounted () {
  this.actMetaData('terms-of-use');
//   document.title= "Terms and Conditions: Terms of Use - Ultra Play";   
//   let metaOgImage = 'https://firebasestorage.googleapis.com/v0/b/ultra-play.appspot.com/o/89_89.png?alt=media&token=689eb558-6fdd-4df1-a67e-4b903c71433';
//   document.querySelector('meta[property="og\\:image"]').setAttribute('content', metaOgImage);
//   document.querySelector('meta[property="og\\:image\\:width"]').setAttribute('content', 200);
//   document.querySelector('meta[property="og\\:image\\:height"]').setAttribute('content', 200);
//   var allMetaElements = document.getElementsByTagName('meta');
//   for(var metaValue = 0; metaValue < allMetaElements.length; metaValue ++) {
//   // if (allMetaElements[metaValue].getAttribute("name") == "description") { 
//   //      allMetaElements[metaValue].setAttribute('content', "Please read about the terms and conditions, terms of use, policies that apply to all subscribers, subscriptions from Ultra Play website"); 
//   // } 

//   // if (allMetaElements[metaValue].getAttribute("property") == "og:description") { 
//   //   allMetaElements[metaValue].setAttribute('content', "Please read about the terms and conditions, terms of use, policies that apply to all subscribers, subscriptions from Ultra Play website"); 
//   // } 

//   // if(allMetaElements[metaValue].getAttribute("name") == "twitter:description") { 
//   //    allMetaElements[metaValue].setAttribute('content', "Please read about the terms and conditions, terms of use, policies that apply to all subscribers, subscriptions from Ultra Play website"); 
//   // } 
//   // if(allMetaElements[metaValue].getAttribute("name") == "keyword") { 
//   //    allMetaElements[metaValue].setAttribute('content', "terms and conditions, terms of use, terms of service, Ultra Play terms of use,"); 

//   // }
//   // if(allMetaElements[metaValue].getAttribute("property") == "og:title") { 
//   //    allMetaElements[metaValue].setAttribute('content', "Terms and Conditions: Terms of Use - Ultra Play"); 
//   // }
//   // if(allMetaElements[metaValue].getAttribute("name") == "twitter:title") { 
//   //    allMetaElements[metaValue].setAttribute('content', "Terms and Conditions: Terms of Use - Ultra Play"); 
//   // }

// }

document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  fetch('https://static-templates.web.app/ultravideo/terms/en.html').then((res) => {
    return res.text()
  }).then((result) => {
    this.dataset = result;
  })
 },
 watch: {
  $route() {
    this.actMetaData('terms-of-use');
  }
 },
 mixins: [Utility],
}
</script>

<style>
.multi-lang{
  text-align: center;
  color: white;
  background-color: black;
  font-size: 24px !important;
  font-weight:bold;
}

.linguval{
   
    position: relative;
    top:20px
}

</style>