<template>
  <div>
    <h1 style="font-size: 1.5rem; text-align: center;padding-top: 2rem;">{{$t('FAQ')}}</h1>
    <div v-html="dataset"></div>
  </div>
</template>
  
<script>
  import Utility from "@/mixins/Utility.js";
  export default {
   data() {
    return {
      dataset: "",
    };
  },
  methods: {
    actInitFaq(flagActive) {

    if (flagActive) {
        window.actScrollView = function () {
        const items = document.querySelectorAll("button");

        for (let item of items) {
          item.setAttribute("aria-expanded", false);
          item.children[2] &&
            item.children[2].setAttribute("aria-expanded", false);
        }
        document.getElementById("reset-password") &&
          document
            .getElementById("reset-password")
            .setAttribute("aria-expanded", true);

        window.scroll({
          top:
            document.getElementById("reset-password").getBoundingClientRect()
              .top +
            window.scrollY -
            60,
          left: 0,
          behavior: "smooth",
        });
      };
      setTimeout(() => {
        const items = document.querySelectorAll("button");
        function toggleAccordion(e) {
          const itemToggle = this.getAttribute("aria-expanded");

          for (let item of items) {
            item.setAttribute("aria-expanded", false);
            item.children[2] &&
              item.children[2].setAttribute("aria-expanded", false);
          }

          if (itemToggle === "false") {
            this.setAttribute("aria-expanded", true);
            this.children[2] &&
              this.children[2].setAttribute("aria-expanded", true);

            var bodyRect = document.body.getBoundingClientRect(),
              offset = e.target.top - bodyRect.top;

            // window.scroll({
            //         top: e.target.getBoundingClientRect().top + window.scrollY - 100,
            //         left: 0,
            //         behavior: 'smooth'
            // });
          }
        }

        items.forEach((item) =>
          item.addEventListener("click", toggleAccordion)
        );

        let rightSideItemList = document.querySelectorAll(
          ".category-title-faq"
        );

        let leftSideItemList = document.querySelectorAll(
          ".accordion-parent-faq"
        );

        function actSelectedItem(e) {
          e.stopPropagation();

          rightSideItemList.forEach((rightSideItem) => {
            rightSideItem.classList.remove("category-title-faq-active");
          });

          leftSideItemList.forEach((leftSideItem) => {
            if (leftSideItem.dataset.identity == e.target.dataset.identity) {
              leftSideItem.classList.remove("display-none");
            } else {
              leftSideItem.classList.add("display-none");
            }
          });

          e.target.classList.add("category-title-faq-active");
        }

        rightSideItemList.forEach((rightSideItem) =>
          rightSideItem.addEventListener("click", actSelectedItem)
        );
      }, 500);
     
    } else {
        setTimeout(() => {
        const items = document.querySelectorAll(".accordion-faq .outer-button");
        const innerItems = document.querySelectorAll(".innerAccordion-faq .inner-button");
        function toggleAccordion(e) {
            console.log("toggleAccordion");
            const itemToggle = this.getAttribute("aria-expanded");

            for (let item of items) {
                item.setAttribute("aria-expanded", false);
                item.children[2] && item.children[2].setAttribute("aria-expanded", false);
            }

            if (itemToggle === "false") {

                this.setAttribute("aria-expanded", true);
                this.children[2] && this.children[2].setAttribute("aria-expanded", true);

                var bodyRect = document.body.getBoundingClientRect(),
                    offset = e.target.top - bodyRect.top;

                window.scroll({
                    top: e.target.getBoundingClientRect().top + window.scrollY - 10,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
        function toggleInnerAccordion(e) {
            console.log("toggleInnerAccordion");
            console.log(e.target.offsetTop)
            const itemToggle = this.getAttribute("aria-expanded");

            for (let item of innerItems) {
                item.setAttribute("aria-expanded", false);
                item.children[2] && item.children[2].setAttribute("aria-expanded", false);
            }

            if (itemToggle === "false") {

                this.setAttribute("aria-expanded", true);
                this.children[2] && this.children[2].setAttribute("aria-expanded", true);

                var bodyRect = document.body.getBoundingClientRect(),
                    offset = e.target.top - bodyRect.top;

                window.scroll({
                    top: e.target.getBoundingClientRect().top + window.scrollY - 100,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }

        items.forEach((item) => item.addEventListener("click", toggleAccordion));
        innerItems.forEach((item) => item.addEventListener("click", toggleInnerAccordion));

    }, 500)


    window.actScrollView = function() {

        const items = document.querySelectorAll("button");

        for (let item of items) {
            item.setAttribute("aria-expanded", false);
            item.children[2] && item.children[2].setAttribute("aria-expanded", false);
        }
        document.getElementById('reset-password') && document.getElementById('reset-password').setAttribute("aria-expanded", true);

        window.scroll({
            top: document.getElementById('reset-password').getBoundingClientRect().top + window.scrollY - 100,
            left: 0,
            behavior: 'smooth'
        });
    }

    }
   
  }
 },
mounted() {
  this.actMetaData('faq');
//   document.title= "FAQs: Frequently Asked Questions - Ultra Play";     
//   let metaOgImage = 'https://firebasestorage.googleapis.com/v0/b/ultra-play.appspot.com/o/89_89.png?alt=media&token=689eb558-6fdd-4df1-a67e-4b903c71433';
//   document.querySelector('meta[property="og\\:image"]').setAttribute('content', metaOgImage);
//   document.querySelector('meta[property="og\\:image\\:width"]').setAttribute('content', 200);
//   document.querySelector('meta[property="og\\:image\\:height"]').setAttribute('content', 200);
//   var allMetaElements = document.getElementsByTagName('meta');
//   for(var metaValue = 0; metaValue < allMetaElements.length; metaValue ++) {
// //   if (allMetaElements[metaValue].getAttribute("name") == "description") { 
// //        allMetaElements[metaValue].setAttribute('content',"Do you have any questions regarding our OTT or services? Learn more by reviewing Ultra Play frequently asked questions section."); 
// //   } 
 
// //   if(allMetaElements[metaValue].getAttribute("property") == "og:description") { 
// //  allMetaElements[metaValue].setAttribute('content', "Do you have any questions regarding our OTT or services? Learn more by reviewing Ultra Play frequently asked questions section."); 
// //   } 

// //   if(allMetaElements[metaValue].getAttribute("name") == "twitter:description") { 
// //      allMetaElements[metaValue].setAttribute('content', "Do you have any questions regarding our OTT or services? Learn more by reviewing Ultra Play frequently asked questions section."); 
// //   } 

// //   if(allMetaElements[metaValue].getAttribute("name") == "keyword") { 
// //      allMetaElements[metaValue].setAttribute('content', "frequently asked questions, FAQs, Ultra Play FAQ,"); 
// //   }

// //   if(allMetaElements[metaValue].getAttribute("property") == "og:title") { 
// //      allMetaElements[metaValue].setAttribute('content', "FAQs: Frequently Asked Questions - Ultra Play"); 
// //   }

// //   if(allMetaElements[metaValue].getAttribute("name") == "twitter:title") { 
// //      allMetaElements[metaValue].setAttribute('content', "FAQs: Frequently Asked Questions - Ultra Play"); 
// //   }
// }
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    window.scrollTo(0, 0);
    let faqCode = localStorage.getItem('setDisplayLanguageCode') == 'eng' ? "https://static-templates.web.app/ultravideo/faq/en/faq-web.html" : "https://static-templates.web.app/ultravideo/faq/hn/faq-web.html"
    let faqMobile = localStorage.getItem('setDisplayLanguageCode') == 'eng' ? 'https://static-templates.web.app/ultravideo/faq/en/faq.html' : 'https://static-templates.web.app/ultravideo/faq/hn/faq.html'
    let urlFaq = window.screen.width > 720 ? faqCode : faqMobile;
    fetch(urlFaq)
      .then((res) => {
        return res.text();
      })
      .then((result) => {
        this.dataset = result;

        this.actInitFaq(window.screen.width > 720 ? true : false);
      });
  },
watch: {
  $route() {
    this.actMetaData('faq');
  }
},
mixins: [Utility],
}

</script>